
import { defineComponent } from "vue";
import { IonLabel } from '@ionic/vue';

export default defineComponent({
  name: "CyanForm",
  components: { IonLabel },
  data() {
    return {};
  },
  props: {
    header: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    logo: {
      type: Boolean,
      default: true,
    },
  },
});
